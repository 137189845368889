
.billing .cc {
    display: block;
    border-radius: 4px;
    border: solid 2px #DDDDDD;
    padding: 15px;
    text-align: center;
    margin-bottom: 15px;
}
.billing .cc:hover {
    border-color: #337ab7;
    background-color: #337ab7;
    color: white;
    text-decoration: none;
}
.billing .cc.selected {
    border-color: #337ab7;
}
.card {
    background-color: white;
    border-radius: 4px;
    padding: 15px;
}
.card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
