.payment-methods .cc {
    display: block;
    border-radius: 4px;
    border: solid 2px #DDDDDD;
    padding: 15px;
    text-align: center;
    margin-bottom: 15px;
}
.payment-methods .cc.add:hover {
    border-color: #337ab7;
    background-color: #337ab7;
    color: white;
    text-decoration: none;
}
.payment-methods .cc.selected {
    border-color: #337ab7;
}
.payment-methods .btn {
    display: block;
    margin: 8px auto;
    width: 200px;
}
